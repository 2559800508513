:root {
  /* --primary: #3e79f7; */
  --primary: #00b050 !important;
  --primary-two: #0000fb44;
  --primary-three: #5353fba1;
  --text: #72849a;
  --active-list: #455560;
  --danger-alt: #ff6b72;
}

.ant-select-arrow {
  /* top: 37% !important; */
  /* transform: translateY(-50%); */
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: left;
  line-height: auto !important;
}


.css-14vsxfy {
  /* min-width: 1284px; */
}

.css-oaakez{
  align-items: center;
}
.ant-btn-primary:not(:disabled){background-color: var(--primary) !important;}
.custom-btn{
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
  padding: 5px 10px;
  height: auto;
}

.ant-btn-primary.custom-status{
  color: #08979c !important;
  background: #e6fffb !important;
  border-color: #87e8de !important;
  padding: 5px 10px;
  height: auto;
}
.custom-btn:hover{
  color: #08979c !important;
  background-color: #e6fffb !important;
  border-color: #87e8de;
}
.ant-btn-dangerous.custom-status{
  color: #d4380d !important;
  background: #fff2e8 !important;
  border-color: #ffbb96 !important;
}
.ant-btn-dangerous.custom-status:hover{
  color: #d4380d !important;
  background: #fff2e8 !important;
  border-color: #ffbb96 !important;
}
.custom-table-image{
  border-radius: 8px;
}
.customNavbtn{background-color: var(--primary) !important;}
.customNavbtn:hover{border-color: var(--primary); background-color: transparent !important; color: var(--primary) !important;}
.ant-menu-light .ant-menu-item-selected {background-color: rgba(0, 176, 80, 0.1);}
.ant-menu-light .ant-menu-item::after{border-inline-end: 3px solid var(--primary) !important;}
.ant-menu-light .ant-menu-item-selected {color: #1b1b1b;}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
    color:  var(--primary);
}
.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected), .ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected){
  color:  var(--primary);
}
/* .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover, .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {background-color: var(--primary)!important;  border-color: transparent;
} */

.ant-checkbox-checked .ant-checkbox-inner {background-color: var(--primary); border-color: var(--primary);}
.ant-checkbox-checked:after{border: 2px solid var(--primary);}
.ant-pagination .ant-pagination-item-active {font-weight: 600; background-color:var(--primary); border-color: #ffffff;}
.ant-checkbox-indeterminate .ant-checkbox-inner:after{background-color:var(--primary);}
.css-69i1ev{flex-wrap: wrap;}
.css-k008qs{flex-wrap: wrap;}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {color:var(--primary);}
.ant-tabs .ant-tabs-ink-bar{background:var(--primary);}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {border-color: var(--primary);}
.ant-input:hover {border-color: var(--primary);}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon .anticon{color: var(--primary);}
.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover {border-color:  var(--primary);}
.css-xb3clo:hover >*, .css-xb3clo.ant-dropdown-open >*, .css-xb3clo.ant-popover-open >* {color: var(--primary) !important;}
.anticon.anticon-search{display: none;}
.ant-btn-link {color:var(--primary);}
.ant-btn-link:not(:disabled):hover:hover {color:var(--primary);}
.ant-btn-link:hover {color:var(--primary);}
.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {color: var(--primary) ;}
.ant-space{width: 100%;}
.ant-space-item{ width: 100%;}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{border-color:var(--primary);}
.ant-btn-default:not(:disabled):hover {color: var(--primary); border-color: var(--primary);}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {color: var(--primary); font-weight: 600; background-color: rgba(0, 176, 80, 0.1);}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {color: var(--primary);}
.ant-select-focused:where(.css-dev-only-do-not-override-10jqpyg).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(5, 122, 255, 0.06);
  outline: 0;
}
.ant-input:focus, :where(.css-dev-only-do-not-override-10jqpyg).ant-input-focused{
  border-color:var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-10jqpyg).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
  border-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after{
  border-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner{
  background-color: var(--primary);
}

.ant-spin .ant-spin-dot-item{background-color: var(--primary);}
.ant-spin{color: var(--primary);}
.css-1cjluxq .css-k008qs{display: flex !important;}

.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >th, .ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td{background: rgba(0, 176, 80, 0.05);}
.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected:hover>th, .ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected:hover>td{background: rgba(0, 176, 80, 0.05);}
.anticon-mail.text-primary {
  color: var(--primary) !important;
}
.anticon-lock.text-primary{color: var(--primary) !important;}
.ant-picker{width: 100%;}
.ant-picker:hover, .ant-picker-focused{width: 100%;   border-color: var(--primary);}
.ant-picker-dropdown .ant-picker-today-btn {color: var(--primary);}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{border: 1px solid var(--primary);}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{    background: var(--primary);}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{border-color: var(--primary);}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover, .ant-radio-button-wrapper:hover{color: var(--primary);}
.ant-switch.ant-switch-checked, .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled){background: var(--primary);}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){border-color: var(--primary);}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{background-color: var(--primary) !important;}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{border-color: var(--primary);}
.ant-menu-light .ant-menu-item-selected{color: var(--primary);}
.delete-btn{background: #ff6b72; color: #fff !important;}
@media screen and (min-width: 1366px){
  .ant-select {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .css-69i1ev{display: block !important;}
  .css-k008qs{display: block !important;}
  .ant-input-affix-wrapper {max-width: 100% !important;}
}

.activeBtn{color: #08979c; background: #e6fffb;border-color: #87e8de;}
.activeBtn:hover{color: #08979c !important;background: #e6fffb !important;border-color: #87e8de !important;}
.inActiveBtn{color: #d4380d;background: #fff2e8 ;border-color: #ffbb96 ;}
.inActiveBtn:hover{background: #fff2e8 !important; color: #d4380d !important;}