:root{
    --primary:#3e79f7;
    --primary-two: #0000fb44;
    --primary-three: #5353fba1;
    --text:#72849a;
    --active-list:#455560;
    --danger-alt:#ff6b72;
}

.left-0{left: 0;}
.top-0{top: 0;}
.shadow-sm{box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);position: relative;}
.show{display: flex;}
.hide{visibility: hidden;opacity:0;transition:all 0.30s ease;}

/*  ----delete popup----  */

.delete_popup{background-color: rgba(0, 0, 0, 0.255);z-index: 1000;}
.delete_popup .popup{border-radius: 5px;width: 400px;}
.delete_popup .popup .dialogue{line-height: 20px;}
.delete_popup .popup .del_btn{background-color: var(--danger-alt);}
.delete_popup .popup .del_btn:focus-visible{outline: 4px solid #ff848ab1;}
.delete_popup .popup .del_btn:hover{border: 1px solid #ff757c!important;}

.custom-btn{color: #08979c; background: #e6fffb; border-color: #87e8de; padding: 5px 10px; height: auto;}
.custom-btn:hover{color: #08979c !important; background-color: #e6fffb !important; border-color: #87e8de;}
.ant-btn-dangerous.custom-btn{color: #d4380d; background: #fff2e8; border-color: #ffbb96;}
.ant-btn-dangerous.custom-btn:hover{color: #d4380d !important; background: #fff2e8 !important; border-color: #ffbb96 !important;}
.custom-table-image{border-radius: 8px;}
/* 
.table-filter-list{padding: 7px 20px;border-radius: 10px;}
.table-filter-list:hover{background-color: #f0f7ff;}
.filter_list_sec .filter_list{height: 165px;overflow-y: scroll;border-bottom: 1px solid rgba(128, 128, 128, 0.311);}
.filter_list_sec .filter_list::-webkit-scrollbar{width: 6px;background-color: transparent;}
.filter_list_sec .filter_list::-webkit-scrollbar-thumb{width: 100%;background-color: var(--primary);border-radius: 5px;}
.filter_list_sec .filter_list::-webkit-scrollbar-corner{background-color: transparent;color: transparent;} */

/* checkbox */

.check_box {border: 2px solid var(--primary);width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt {border: 2px solid #e6ebf1;width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt:hover {border: 2px solid var(--primary);width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt.active{background-color: var(--primary);border-color: var(--primary);}

.table-filter-list{padding: 7px 20px;border-radius: 10px;}
.table-filter-list:hover{background-color: #f0f7ff;}
.filter_list_sec .filter_list{max-height: 165px;overflow-y: scroll;border-bottom: 1px solid rgba(128, 128, 128, 0.311);}
.filter_list_sec .filter_list::-webkit-scrollbar{width: 6px;background-color: transparent;}
.filter_list_sec .filter_list::-webkit-scrollbar-thumb{width: 100%;background-color: var(--primary);border-radius: 5px;}
.filter_list_sec .filter_list::-webkit-scrollbar-corner{background-color: transparent;color: transparent;}

/* UPLOAD IMAGE COMPONENT */
.file_upload_sec {border: 1px dashed gray; height: 150px;cursor: pointer;}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon,
.file_upload_sec .img_icon_right{width: 50px;height: 50px;display: flex;}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon_right{filter: grayscale(1);opacity: 0.6;}

.file_upload_sec .img_icon_left{right: -50px;transition: all 0.30s ease;}
.file_upload_sec .img_icon_right{left: -50px;transition: all 0.30s ease;}
.file_upload_sec:hover .img_icon_left{right: -20px;transform: rotate(-20deg);transition: all 0.30s ease;}
.file_upload_sec:hover .img_icon_right{left: -20px;transform: rotate(20deg);transition: all 0.30s ease;}

.file_upload_sec .img_icon{z-index: 1;}
.file_upload_sec .img_icon img{width: 100%;height: 100%;object-fit: contain; object-position: center;}
.file_upload_sec .uploaded_img{object-fit: cover;object-position: center;}

.file_upload_input{width: 100%;height: 100%;opacity: 0;z-index: 2;cursor: pointer;}
.img_delete_sec{top:0;left: 0;background-color: rgba(174, 173, 173, 0.41);}
.delete_btn{border: 1px solid #ff6b72;display: flex;align-items: center;justify-content: center;width: 40px; height: 40px;border-radius: 50%;background-color: #ff6b72;transition:all 0.30s ease;}
.delete_btn:hover{scale: 1.2;transition:all 0.30s ease;}